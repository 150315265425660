* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.rootHome {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-image: url('../../../../images/frontUI/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}